var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-table", {
              ref: "table",
              attrs: {
                title: "설비별 점검결과",
                isTitle: true,
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                editable: _vm.editable && !_vm.disabled,
                data: _vm.result.equipmentResultList,
                gridHeightAuto: true,
                merge: _vm.grid.merge,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                hideBottom: true,
                noDataLabel: "설비에 대한 점검항목이 없습니다.",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable && !_vm.disabled,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey: "minEquipmentTypeCheckItemId",
                                ibmTaskTypeCd: "ITT0000055",
                                ibmTaskUnderTypeCd: "ITTU000085",
                                acceptBtnShow: true,
                              },
                              on: { imprChange: _vm.imprChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "customArea",
                                    fn: function ({ props }) {
                                      return [
                                        _c("div", { staticClass: "col-6" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mini-dailog-title",
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "pe-7s-angle-right-circle",
                                              }),
                                              _vm._v(" 점검결과 "),
                                            ]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c("c-text", {
                                              attrs: {
                                                editable:
                                                  _vm.editable && !_vm.disabled,
                                                label: "",
                                                name: "checkResult",
                                              },
                                              model: {
                                                value: props.row.checkResult,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.row,
                                                    "checkResult",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "props.row.checkResult",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._l(
                                          _vm.customReadOnlyCols,
                                          function (item) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  key: item.key,
                                                  staticClass: "col-12",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "col-6",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mini-dailog-title",
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "pe-7s-angle-right-circle",
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.label
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      props.row[item.name]
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12 border-content-dialog",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    props.row[
                                                                      item.name
                                                                    ]
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          )
                                                        : _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-12 border-nocontent-dialog",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    "No Data"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          }
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }